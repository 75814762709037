<template>
  <div class="church-presentation-template">
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <b-container class="">
          <b-row class="">
            <b-col cols="12">
              <h1 class="sm">{{ isEditMode ? translations.tcEditConversation : translations.tcAddConversation }} &mdash; {{ conversation.selectedStateCountry.text }}</h1>
              <ConversationPageNavigation
                :i18n="translations.components"
                :page="currentPage"
              ></ConversationPageNavigation>
              <hr />
            </b-col>
          </b-row>
        </b-container>
        <b-form @submit.prevent class="view-1 g-mb-2">
          <b-container>
            <b-row>
              <b-col cols="12">
                <b-container>
                  <b-row>
                    <b-col col md="6" xs="12">
                      <b-form-group :label="translations.tcEventType" class="font-style-2 mb-0">
                        <b-form-select
                          v-model="eventType"
                          name="event-type"
                          title="event-type"
                          :options="eventTypeTranslated"
                          @change="changeMemberType($event)"
                          class="form-control g-select"
                        >
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col col md="6" xs="12">
                      <b-form-group :label="translations.tcEventTitle" class="font-style-2 mb-0">
                        <b-form-input
                          v-model="eventTitle"
                          :state="!!eventTitle"
                          id="event-input"
                          :placeholder="translations.tcEventTitle"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <hr />
                <div class="h-style-1">{{ translations.tcLocation }}</div>
              </b-col>
            </b-row>
            <!--
              This is commented out because we only need physical locations for now
              could add in virutal later if needed
              <b-row>
              <b-col cols="12" xs="12">
                <b-form-radio-group
                  id="radios-location-type"
                  v-model="locationTypeSelected"
                  :options="locationTypeOptions"
                  button-variant="outline-primary"
                  name="radios-location-type"
                  size="sm"
                  buttons
                ></b-form-radio-group>
              </b-col>
            </b-row> -->
            <b-row>
              <b-col cols="12">
                <b-container id="loc-physical" v-if="locationTypeSelected === 'physical'">
                  <b-row>
                    <b-col cols="12">
                      <p class="mb-3">{{ locationDisplay }}</p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3" xs="12">
                      <location-search
                        @location-select="handleLocationSelect"
                        :cnt_key="this.userCountryKey"
                        :i18n="translations.components"
                      />
                    </b-col>
                    <b-col md="4" xs="12">
                      <b-button
                        variant="primary"
                        class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0 mx-4"
                        @click="addLocation"
                        >{{ translations.tcAddNewLocation }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-container>
                <!-- <b-container id="loc-virtual" v-if="locationTypeSelected === 'virtual'">
                  <b-row>
                    <b-col cols="12">
                      <p class="mb-3">{{ locationVirtualDisplay }}</p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="4" xs="12">
                      <location-virtual-search
                        @location-virtual-select="handleVirtualLocationSelect"
                        :cnt_key="this.userCountryKey"
                        :i18n="translations.components"
                      />
                    </b-col>
                    <b-col md="4" xs="12">
                      <b-button
                        variant="primary"
                        class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0 mx-0 mt-4 mt-lg-0 mx-lg-4"
                        @click="addLocationVirtual"
                        >{{ translations.tcAddNewVirtualLocation }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-container> -->
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12"> </b-col>
            </b-row>
            <b-row class="">
              <b-col cols="12">
                <b-container>
                  <b-row>
                    <b-col col md="6" xs="12" class="p-0">
                      <div class="h-style-1">{{ translations.tcEducationalUnit }}</div>
                      <b-form-select
                        class="square-corner"
                        v-model="educationalUnitKey"
                        :options="educationalUnitOptions"
                      ></b-form-select>
                    </b-col>
                    <b-col col md="6" xs="12">
                      <div class="h-style-1">{{ translations.tcInstructor }}</div>
                      <b-form-select
                        class="square-corner"
                        v-model="instructorKey"
                        :options="instructorsOptions"
                      ></b-form-select>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col col md="6" xs="12" class="selectOption p-0">
                      <div class="h-style-1">{{ translations.tcLocalContact }}</div>
                      <b-button
                        v-if="!localContact"
                        :name="`localContactAddButton`"
                        variant="primary"
                        class="flex-1 btn-pl-xsm"
                        @click="showModal($event, 'addNonMemberModal')"
                        >{{ translations.tcAddLocalContact }}</b-button
                      >
                      <div v-if="!!localContact" class="selectMemberOption">
                        <div class="memberName">{{ localContact.text }}</div>
                        <div @click="deleteLocalContact()" class="deleteOption">
                          <ServiceDelete />
                        </div>
                      </div>
                    </b-col>
                    <b-col col md="6" xs="12">
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
              <b-col md="6" xs="12"> </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <hr />
                <div class="h-style-1">{{ translations.tcStartEndDate }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="6">
                <b-form-datepicker
                  id="startDate"
                  v-model="startEndDate.startDate"
                  class="mb-2 contact_date"
                  :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                  :locale="prefCulture"
                  :label-help="translations.tcCalHelp"
                  :label-no-date-selected="translations.tcDatePickerPlaceholder"
                  :placeholder="translations.tcDatePickerPlaceholder"
                  :show-decade-nav="showDecadeNav"
                  :hide-header="hideHeader"
                  size="sm"
                >
                  <template #button-content style="padding: 12px">
                    <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                  </template>
                </b-form-datepicker>
              </b-col>
              <b-col v-if="this.eventType != this.TwoHourConversation">
                <b-form-datepicker
                  id="endDate"
                  v-model="startEndDate.endDate"
                  class="mb-2 contact_date"
                  :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                  :locale="prefCulture"
                  :label-help="translations.tcCalHelp"
                  :label-no-date-selected="translations.tcDatePickerPlaceholder"
                  :placeholder="translations.tcDatePickerPlaceholder"
                  :show-decade-nav="showDecadeNav"
                  :hide-header="hideHeader"
                  size="sm"
                >
                  <template #button-content style="padding: 12px">
                    <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                  </template>
                </b-form-datepicker>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="h-style-1">{{ translations.tcStartTimeEndTime }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-timepicker
                  v-model="startTime"
                  minutes-step="5"
                  :locale="prefCulture"
                  :label-close-button="translations.tcClose"
                  :label-no-time-selected="translations.tcNoTimeSelected"
                >
                </b-form-timepicker>
              </b-col>
              <b-col>
                <b-form-timepicker
                  v-model="endTime"
                  minutes-step="5"
                  :locale="prefCulture"
                  :label-close-button="translations.tcClose"
                  :label-no-time-selected="translations.tcNoTimeSelected"
                >
                </b-form-timepicker>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mt-3">
                <b-button variant="primary" class="mr-3" @click="handleBackClick()">
                  {{ translations.tcBack }}
                </b-button>
                <b-button variant="primary" class="mr-3" @click="handleNextClick()">
                  {{ translations.tcNext }}
                </b-button>
                <b-button variant="tertiary" @click="handleCancelClick()">{{ translations.tcCancel }}</b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
        <section class="form-buttons"></section>
      </section>
    </page-body>
    <add-meeting-location @locationSelectionMade="applyNewLocation($event)" v-if="translations" :i18n="translations" />
    <add-meeting-location-virtual
      @locationVirtualSelectionMade="applyNewLocationVirtual($event)"
      :i18n="translations"
    />
    <AddNonMemberModal
      @selectionMade="applyLocalContact($event)"
      :i18n="translations"
      :modal_title="local_contact_modal_title"
      :phoneRequired="phoneRequired"
      :emailRequired="emailRequired"
      :cnt_key="camp_country || this.demograph.country_key"
    ></AddNonMemberModal>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AddMeetingLocation from '@/components/AddMeetingLocation.vue'
import AddMeetingLocationVirtual from '@/components/AddMeetingLocationVirtual.vue'
import AddNonMemberModal from './AddNonMemberModal.vue'
import constantData from '@/json/data.json'
import ConversationPageNavigation from './conversation-page-navigation.vue'
import LocationSearch from '@/components/calendar/calendar-search/LocationSearch.vue'
import LocationVirtualSearch from '@/components/calendar/calendar-search/LocationVirtualSearch.vue'
import { ModelSelect } from 'vue-search-select'
import pageBody from '@/components/page-components/PageBody.vue'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-conversation-event-details',
  mixins: [translationMixin],
  data() {
    return {
      activeNext: false,
      currentPage: 2,
      defaultlocation: null,
      defaultlocationVirtual: null,
      disableNext: true,
      educationalUnitKey: null,
      endTime: null,
      //educationalUnitOptions: [],
      errorMessage: null,
      eventType: null,
      //instructorOptions: [],
      emailRequired: false,
      eventTitle: '',
      hideHeader: true,
      instructorKey: null,
      isEditMode: false,
      localContact: null,
      local_contact_modal_title: 'Add Local ContactX',
      local_conversation: {
        educationalUnitKey: null,
        endTime: null,
        eventTitle: '',
        eventType: null,
        instructorKey: null,
        localContact: null,
        location: null,
        memberTypeSelected: null,
        startEndDate: { startDate: null, endDate: null },
        startTime: null,
        virtualLocation: null
      },
      location: null,
      locationTypeSelected: 'physical',
      locationTypeOptions: [],
      memberTypeSelected: null,
      phoneRequired: true,
      showDecadeNav: true,
      startEndDate: { startDate: null, endDate: null },
      startTime: null,
      translations: {},
      validateError: [],
      virtualLocation: null
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations(
          'conversation-page-navigation',
          'add-meeting-location',
          'add-meeting-location-virtual',
          'location-search',
          'location-virtual-search',
          'conversation-add-non-member'
        ),
        this.getComponentTranslations('common.calendar-event-type-conversation')
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
        //this.loadTranslations()
        this.setLocationTypeOptions()
      })
      await this.page_load()
      await this.hydrateConversation()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      clearConversation: 'conversations/clearConversation',
      loadEducationalUnits: 'conversations/loadEducationalUnits',
      loadInstructors: 'conversations/loadInstructors',
      loadMeetingTypes: 'conversations/loadMeetingTypes',
      loadCountryByOFTB: 'conversations/loadCountryByOFTB',
      setLoadingStatus: 'menu/setLoadingStatus',
      updateConversationEventDetails: 'conversations/updateConversationEventDetails'
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        const meetingTypePayload = {
          type: 'conversation',
          lang_key: this.demograph.language_key
        }
        const euPayload = {
          org_key: this.officerToolbarSelected.country_state,
          lang_key: this.demograph.language_key
        }
        await Promise.all([
          this.loadMeetingTypes(meetingTypePayload),
          this.loadEducationalUnits(euPayload),
          this.loadInstructors(euPayload),
          this.loadCountryByOFTB(euPayload)
        ]).then(() => { })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    addLocation() {
      this.$root.$emit('bv::show::modal', 'addMeetingLocationModal')
    },
    addLocationVirtual() {
      this.$root.$emit('bv::show::modal', 'addMeetingLocationVirtualModal')
    },
    async applyLocalContact(evt) {
      this.localContact = evt
    },
    async applyNewEducationalUnit(evt) {
      this.educationalUnitKey = evt
    },
    async applyNewLocation(evt) {
      //this.selectLocation = evt.key
      this.location = {
        org_key: evt.key,
        org_name: evt.name
      }
      //this.defaultlocation = evt.name
      //this.selectLocationVirtual = null
      //this.defaultlocationVirtual = null
      this.virtualLocation = null
    },
    async applyNewLocationVirtual(evt) {
      //this.selectLocationVirtual = evt.lvt_key
      this.virtualLocation = {
        lvt_key: evt.key,
        lvt_name: evt.name
      }
      //this.defaultlocationVirtual = evt.name
      //this.selectLocation = constants.virtual_location_org_key
      this.location = null
      //this.defaultlocation = null
    },
    changeMemberType(evt) {
      switch (evt) {
        case '19734298-7ab3-4281-99d7-9723c45e347f':
          //two hour conversations are for all members
          this.memberTypeSelected = this.memberAllTypKey
          break
        default:
          // two day training is for Gideons only
          this.memberTypeSelected = this.memberGideonTypKey
          break
      }
    },
    deleteLocalContact() {
      this.localContact = null
    },
    async handleBackClick() {
      const savedData = await this.saveDataToStore(false)
      if (savedData) {
        this.$router.push({ name: 'conversations-add' })
      }
    },
    handleCancelClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkayToCancel,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel
      }).then(result => {
        if (result.value) {
          this.clearConversation()
          this.$router.push({ name: 'conversations-landing-page' })
        }
      })
    },
    handleLocationSelect(location) {
      this.location = location
      //this.selectLocation = null
      //this.defaultlocation = location.org_name
      // this.selectLocationVirtual = null
      this.virtualLocation = null
      //this.defaultlocationVirtual = null
    },
    handleVirtualLocationSelect(location) {
      this.virtualLocation = location
      //this.selectLocationVirtual = null
      //this.defaultlocation = null
      //this.defaultlocationVirtual = location.lvt_name
      //this.selectLocation = constants.virtual_location_org_key
      this.location = null
    },
    async handleNextClick() {
      const savedData = await this.saveDataToStore(true)
      if (savedData) {
        this.$router.push({ name: 'conversations-add-attendees' })
      }
    },
    async hydrateConversation() {
      try {
        this.setLoadingStatus(true)
        //get values from the store and populate local variables
        this.educationalUnitKey = this.conversation.educationalUnitKey
        this.eventType = this.conversation.eventType
        this.eventTitle = this.conversation.eventTitle
        this.instructorKey = this.conversation.instructorKey
        this.localContact = this.conversation.localContact && this.conversation.localContact.value ? this.conversation.localContact : null
        this.location = !!this.conversation.location ? JSON.parse(JSON.stringify(this.conversation.location)) : null
        this.virtualLocation = !!this.conversation.virtualLocation
          ? JSON.parse(JSON.stringify(this.conversation.virtualLocation))
          : null
        if (this.virtualLocation) {
          this.locationTypeSelected = 'virtual'
        }
        this.memberTypeSelected = this.conversation.memberTypeSelected
        this.startEndDate = JSON.parse(JSON.stringify(this.conversation.startEndDate))
        this.startTime = this.conversation.startTime
        this.endTime = this.conversation.endTime

        this.disableNext = false
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async saveDataToStore(validateForm = true) {
      if (validateForm) {
        this.validateEventDetails()

        if (this.validateError.length !== 0) {
          await this.$swal({
            icon: 'error',
            text: `${this.translations.tcErrorFieldsRequired} '${this.validateError.join(', ')}'`,
            confirmButtonText: this.translations.tcOk || 'Ok'
          }).then(result => {
            return false
          })
          return false
        }
      }

      this.local_conversation.educationalUnitKey = this.educationalUnitKey
      this.local_conversation.eventType = this.eventType
      this.local_conversation.eventTitle = this.eventTitle
      this.local_conversation.instructorKey = this.instructorKey
      this.local_conversation.localContact = this.localContact
      this.local_conversation.location = !!this.location ? JSON.parse(JSON.stringify(this.location)) : null
      this.local_conversation.virtualLocation = !!this.virtualLocation
        ? JSON.parse(JSON.stringify(this.virtualLocation))
        : null
      this.local_conversation.memberTypeSelected = this.memberTypeSelected
      if (this.eventType === this.TwoHourConversation) {
        this.startEndDate.endDate = this.startEndDate.startDate
      }
      this.local_conversation.startEndDate = JSON.parse(JSON.stringify(this.startEndDate))
      this.local_conversation.startTime = this.startTime
      this.local_conversation.endTime = this.endTime
      await this.updateConversationEventDetails(this.local_conversation)
      return true
    },
    setLocationTypeOptions() {
      this.locationTypeOptions = [
        {
          text: this.translations.tcPhysicalLocation ? this.translations.tcPhysicalLocation : 'Physical Location',
          value: 'physical'
        },
        {
          text: this.translations.tcVirtualLocation ? this.translations.tcVirtualLocation : 'Virtual Location',
          value: 'virtual'
        }
      ]
    },
    showModal(e, modalName) {
      this.local_contact_modal_title = this.translations.tcAddLocalContact
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    translate(text) {
      // helper function to shorten a translation lookup
      // text should be a value that exists in `this.translations`, IE 'monday', 'january', 'third'
      // This method will convert the `text` into something like `tcMonday`, `tcJanuary`, `tcThird`
      return this.translations[this.convertValForTranslation(text)]
    },
    validateEventDetails() {
      this.validateError.splice(0, this.validateError.length)

      if (!this.eventType) {
        this.validateError.push(this.translations.tcEventType)
      }

      if (!this.eventTitle) {
        this.validateError.push(this.translations.tcEventTitle)
      }

      if (!this.location && !this.virtualLocation) {
        this.validateError.push(this.translations.tcLocation)
      }

      // COMMENTED OUT BC NOT SURE IF THEY WILL BE COMING BACK
      // if (!this.educationalUnitKey) {
      //   this.validateError.push(this.translations.tcEducationalUnit)
      // }

      // if (!this.instructorKey) {
      //   this.validateError.push(this.translations.tcInstructor)
      // }

      // if (!this.localContact) {
      //   this.validateError.push(this.translations.tcLocalContact)
      // }

      if (
        !this.startEndDate ||
        !this.startEndDate.startDate ||
        (this.eventType !== this.TwoHourConversation && !this.startEndDate.endDate)
      ) {
        this.validateError.push(this.translations.tcStartEndDate)
      }

      if (!this.startTime || !this.endTime) {
        this.validateError.push(this.translations.tcStartTimeEndTime)
      }

      if (this.validateError.length > 0) {
        this.errorMessage = `${this.translations.tcErrorFieldsRequired} '${this.validateError.join(', ')}'`
      }
    }
  },
  watch: {
    // distributionSelected(newval, oldval) {
    //   if (newval.selected) {
    //     if (newval.key === 'full') {
    //       this.isSelectedAreas = false
    //       this.activeNext = false
    //     } else {
    //       this.isSelectedAreas = true
    //       this.activeNext = !this.selectedAreas.length > 0
    //     }
    //   }
    // }
  },
  computed: {
    ...mapGetters({
      conversation: 'conversations/conversation',
      demograph: 'user/demograph',
      educational_units: 'conversations/educational_units',
      instructors: 'conversations/instructors',
      meeting_types: 'conversations/meeting_types',
      officerToolbarSelected: 'user/officerToolbarSelected',
      otfbCountry: 'conversations/otfbCountry',
      prefCulture: 'user/userPreferredCulture',
      selectedConversationKey: 'user/userSelectedConversationKey',
      userCountryKey: 'user/userCountryKey'
    }),
    camp_country() {
      //this.otfbCountry.cnt_key is used if the user is an IO country
      if (Object.keys(this.otfbCountry).length > 0 && this.otfbCountry.cnt_key) {
        return this.otfbCountry.cnt_key
      }
      return null
    },
    eventTypeTranslated() {
      const position0 = {
        value: null,
        text: this.translations.tcSelectEventType ? this.translations.tcSelectEventType : 'Select Event Type',
        disabled: true,
        selected: true
      }
      let options = this.meeting_types.map(item => {
        let text = item.mtt_description_text
        if (!!this.translations.common && !!this.translations.common['calendar-event-type-conversation']) {
          text = this.translations.common['calendar-event-type-conversation'][item.mtt_key] || item.mtt_description_text
        }
        const translatedItem = { value: item.mtt_key, text: text }
        return translatedItem
      })
      options.unshift(position0)
      return options
    },
    educationalUnitOptions() {
      const position0 = {
        value: null,
        text: this.translations.tcSelectEductionalUnit
          ? this.translations.tcSelectEductionalUnit
          : 'Select Educational Unit',
        disabled: true,
        selected: true
      }
      let sortedList = this.educational_units.sort((a, b) => {
        if (a.sort_name < b.sort_name) return -1
        if (a.sort_name > b.sort_name) return 1
        return 0
      })
      let options = sortedList.map(item => {
        return { value: item.ind_key, text: item.name }
      })
      options.unshift(position0)
      return options
    },
    instructorsOptions() {
      const position0 = {
        value: null,
        text: this.translations.tcSelectInstructor ? this.translations.tcSelectInstructor : 'Select Instructor',
        disabled: true,
        selected: true
      }
      // concatenate the list of educationalUnits and instructors then remove duplicates
      let instructors = this.educational_units.concat(this.instructors)
      let uniqueInstructors = instructors.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            t =>
              t.ind_key === thing.ind_key &&
              t.name === thing.name &&
              t.sort_name === thing.sort_name &&
              t.ind_key === thing.ind_key
          )
      )
      let sortedList = uniqueInstructors.sort((a, b) => {
        if (a.sort_name < b.sort_name) return -1
        if (a.sort_name > b.sort_name) return 1
        return 0
      })
      let options = sortedList.map(item => {
        return { value: item.ind_key, text: item.name }
      })
      options.unshift(position0)
      return options
    },
    locationDisplay() {
      if (!this.location) return this.translations.tcNoLocationSelected || 'No Location Selected'
      if (!!this.location && !!this.location.org_name) return this.location.org_name

      // if (!this.location && !this.defaultlocation)
      //   return this.translations.tcNoLocationSelected || 'No Location Selected'
      // if (!!this.location && !!this.location.org_name) return this.location.org_name
      // return this.defaultlocation
    },
    locationVirtualDisplay() {
      if (!this.virtualLocation) return this.translations.tcNoLocationSelected || 'No Location Selected'
      if (!!this.virtualLocation && !!this.virtualLocation.lvt_name) return this.virtualLocation.lvt_name

      // if (!this.virtualLocation && !this.defaultlocationVirtual) {
      //   return this.translations.tcNoLocationSelected || 'No Location Selected'
      // } else if (!!this.virtualLocation && !!this.virtualLocation.lvt_name) {
      //   return this.virtualLocation.lvt_name
      // } else {
      //   return this.defaultlocationVirtual
      // }
    },
    memberGideonTypKey() {
      return constantData.member_types[constantData.member_types.map(m => m.text).indexOf('Gideon')].value
    },
    memberAllTypKey() {
      return constantData.member_types[constantData.member_types.map(m => m.text).indexOf('All')].value
    },
    TwoHourConversation() {
      return constantData.ConversationMeetingTypes.TwoHourConversation
    }
  },
  components: {
    'add-meeting-location': AddMeetingLocation,
    'add-meeting-location-virtual': AddMeetingLocationVirtual,
    AddNonMemberModal,
    ConversationPageNavigation: ConversationPageNavigation,
    LocationSearch,
    LocationVirtualSearch,
    ModelSelect,
    pageBody: pageBody,
    ServiceDelete: ServiceDelete
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.page-body {
  padding: 80px 15px;
}
.view-1 {
  @include breakpoint(sm) {
    .btn-group {
      width: 100%;
    }
  }
}
.square-corner {
  border-radius: 0;
}
.form-control {
  height: auto;
  font-weight: normal;
  text-transform: none;
  line-height: 25px;
  padding: 10px 20px;
  border-color: #636363;
  color: #636363;
}

// add a class for all the labels inside a div with id radios-location-type
#radios-location-type > label {
  border-radius: 0 !important;
  border: 1px solid #ced4da;
  border-right: none;
  &:last-child {
    border-right: 1px solid #ced4da;
  }
}
// #radios-location-type > label.btn.btn-outline-primary.btn-sm.active,
// #radios-location-type > label:nth-child(2) {
//   border-radius: 0;
// }

.selectMemberOption {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  .memberName {
    display: block;
  }
  .deleteOption {
    cursor: pointer;
    display: inline-block;
  }
}

.bg-block {
  padding: 24px;
}
</style>
